import { getBaseCartPath } from './common'
import { appendCsrfParams } from '~/common/helpers/csrf'

// can be moved into checkout_step_component_controller.js once we eliminate old design
export function sendSimpleroId(event) {
  const target = event.currentTarget
  event.preventDefault()
  let params = new URLSearchParams()
  appendCsrfParams(params)
  fetch(getBaseCartPath() + '/send_simplero_id', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: params,
  })
    .then((response) => response.json())
    .then((json) => {
      target.replaceWith(json.message)
    })
}

window.addEventListener('DOMContentLoaded', () => {
  let sendIdLink = document.querySelector(
    '.cart-checkout-page .send-simplero-id'
  )
  if (sendIdLink) {
    sendIdLink.addEventListener('click', sendSimpleroId)
  }
})
